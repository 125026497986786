import { applyMiddleware, combineReducers, createStore as createReduxStore } from 'redux';
import { paymentProfilesReducer } from './payment-profiles/reducers';
import thunk from 'redux-thunk';
import { flyOutsReducer } from './fly-outs/reducers';
import { airportZonesReducer } from './airport-zones/reducers';
import { offerReducer } from './offer/reducers';
import createOnboardingReducer from './onboarding/reducers';
import {
  MB_RENT_ONBOARDING_SKIPPED_KEY,
  MB_RENT_SHOW_ONBOARDING_KEY,
  SHARE_NOW_ONBOARDING_SKIPPED_KEY,
  SHARE_NOW_SHOW_ONBOARDING_KEY,
} from '@/constants/cache';

export const SHARE_NOW_ONBOARDING = 'SHARE_NOW_ONBOARDING';
export const MB_RENT_ONBOARDING = 'MB_RENT_ONBOARDING';

export const rootReducer = combineReducers({
  paymentProfiles: paymentProfilesReducer,
  flyOuts: flyOutsReducer,
  onboarding: createOnboardingReducer(
    SHARE_NOW_ONBOARDING,
    SHARE_NOW_ONBOARDING_SKIPPED_KEY,
    SHARE_NOW_SHOW_ONBOARDING_KEY
  ),
  mbRentOnboarding: createOnboardingReducer(
    MB_RENT_ONBOARDING,
    MB_RENT_ONBOARDING_SKIPPED_KEY,
    MB_RENT_SHOW_ONBOARDING_KEY
  ),
  offer: offerReducer,
  airportZones: airportZonesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const createStore = () => createReduxStore(rootReducer, applyMiddleware(thunk));
export default createStore;
