import { LOAD_STATUS } from '../load-status';
import {
  PaymentProfilesActions,
  SET_PAYMENT_PROFILES,
  SET_PAYMENT_PROFILES_LOAD_STATUS, SET_SELECTED_PAYMENT_PROFILE_ID,
} from './types';
import { PaymentProfile } from '@/types/payment-profile';

interface PaymentProfilesState {
  data: PaymentProfile[];
  loadStatus: LOAD_STATUS;
  selectedPaymentProfileId: number | null,
}

const initialState: PaymentProfilesState = {
  data: [],
  loadStatus: LOAD_STATUS.NOT_LOADED,
  selectedPaymentProfileId: null,
};

export const paymentProfilesReducer = (state = initialState, action: PaymentProfilesActions) => {
  switch (action.type) {
    case SET_PAYMENT_PROFILES:
      return { ...state, data: [...action.payload] };
    case SET_PAYMENT_PROFILES_LOAD_STATUS:
      return { ...state, loadStatus: action.payload };
    case SET_SELECTED_PAYMENT_PROFILE_ID:
      return { ...state, selectedPaymentProfileId: action.payload };
    default:
      return state;
  }
};
