import { clearCachedSessionLocation, getCachedSessionLocation } from '@/utils/global-cache';
import { navigate } from 'gatsby';
import React from 'react';
import { Provider } from 'react-redux';
import createStore from './src/store';

// wraps Gatsby with react-redux provider
export default ({ element }) => {

  // redirect webviews to cached session url if present
  const cachedLocation = getCachedSessionLocation();
  if (cachedLocation) {
    clearCachedSessionLocation();
    navigate(cachedLocation);
  }

  const store = createStore();
  return <Provider store={store}>
    {element}
  </Provider>;
}