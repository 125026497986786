import Layout from '@/components/layout/layout';
import { DEFAULT_CITY } from '@/config/defaults';
import { TranslationContext } from '@/hooks/use-translation/use-translation';
import GatsbyPage from '@/types/gatsby-page';
import { DEFAULT_LANGUAGE } from '@rental/prebooking-ui-utils';
import React, { useContext } from 'react';

import '@/scss/_base.scss';

import { AutomaticDataProvider } from '@/hooks/use-data/use-data';
import { BookingProvider } from '@/hooks/use-booking/use-booking';
import { PricingProvider } from '@/hooks/use-pricing/use-pricing';
import GoogleAnalytics from '../google-analytics/google-analytics';
import Sentry from '@/components/sentry/sentry';
import { UsercentricsProvider } from '@/hooks/use-usercentrics/use-usercentrics';
import VehiclesProvider from '@/hooks/use-vehicle-categories/use-sn-vehicle-categories';

const defaultContext = {
  webView: false,
  cityName: DEFAULT_CITY,
  language: DEFAULT_LANGUAGE,
  translations: {},
};
const PageContext = React.createContext(defaultContext);
PageContext.displayName = 'PageContext';

// layout needs to be wrapped with div to ensure that all root classes render properly
const PageContextProvider: GatsbyPage = ({ children, pageContext }) => {

  const context = { ...defaultContext, ...pageContext };
  if (!pageContext.language) return children

  return <div>
    {context.webView && <GoogleAnalytics />}
    <PageContext.Provider value={context}>
      <TranslationContext.Provider value={context.translations}>
        <AutomaticDataProvider>
          <UsercentricsProvider webView={context.webView}>
            <Sentry />
            <BookingProvider>
              <VehiclesProvider webView={context.webView}>
                <PricingProvider>
                  <Layout webView={context.webView}>
                    {children}
                  </Layout>
                </PricingProvider>
              </VehiclesProvider>
            </BookingProvider>
          </UsercentricsProvider>
        </AutomaticDataProvider>
      </TranslationContext.Provider>
    </PageContext.Provider>
  </div>;
};

export default PageContextProvider;

export const usePageContext = () => {
  return useContext(PageContext);
};
