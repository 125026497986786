import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { closePaymentProfiles, openPaymentProfiles } from '@/store/fly-outs/actions';
import { setSelectedPaymentProfileId } from '@/store/payment-profiles/actions';

const usePaymentProfiles = () => {
  const dispatch = useDispatch();
  const setSelectedPaymentProfile = (paymentProfileId: number) => dispatch(setSelectedPaymentProfileId(paymentProfileId));

  const paymentProfiles = useSelector((state: RootState) => state.paymentProfiles.data);
  const paymentProfilesLoadStatus = useSelector((state: RootState) => state.paymentProfiles.loadStatus);

  const selectedPaymentProfileId = useSelector((state: RootState) => state.paymentProfiles.selectedPaymentProfileId);
  const selectedPaymentProfile = paymentProfiles.find((paymentProfile) => paymentProfile.paymentProfileId === selectedPaymentProfileId);

  const openPaymentProfilesFlyOut = () => dispatch(openPaymentProfiles());
  const closePaymentProfilesFlyOut = () => dispatch(closePaymentProfiles());
  const paymentProfilesFlyOutOpen = useSelector((state: RootState) => state.flyOuts.paymentProfilesOpen);

  return {
    selectedPaymentProfile,
    setSelectedPaymentProfile,
    paymentProfiles,
    paymentProfilesLoadStatus,
    openPaymentProfilesFlyOut,
    closePaymentProfilesFlyOut,
    paymentProfilesFlyOutOpen,
  };
};

export default usePaymentProfiles;
