import useBooking from '@/hooks/use-booking/use-booking';
import useCategoryInfo from '@/hooks/use-category-info/use-category-info';
import useMbRent from '@/hooks/use-mb-rent/use-mb-rent';

const useSelectedCategory = () => {
  const { values } = useBooking();
  const { active: mbRentActive } = useMbRent();

  if (mbRentActive) {
    return useCategoryInfo(values.mbr_category.vehicleType);
  }

  const vehicleType = values.trip.vehicleType;

  return useCategoryInfo(vehicleType);
};

export default useSelectedCategory;
