import useVehicleCategories from '@/hooks/use-vehicle-categories/use-vehicle-categories';
import VehicleCategory from '@/types/vehicle-category';

const useCategoryInfo = (vehicleType: VehicleCategory | null) => {
  const { vehicleCategories } = useVehicleCategories();

  return vehicleCategories.find(({ vehicleCategory }) => vehicleCategory === vehicleType);
};

export default useCategoryInfo;
