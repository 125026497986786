import { OfferActions, SET_OFFER, SET_OFFER_LOAD_STATUS } from './types';
import { LOAD_STATUS } from '../load-status';
import { Offer } from '@/types/offer';

export const setOffer = (offer: Offer[]): OfferActions => ({
  type: SET_OFFER,
  payload: offer,
});

export const setOfferLoadStatus = (loadStatus: LOAD_STATUS): OfferActions => ({
  type: SET_OFFER_LOAD_STATUS,
  payload: loadStatus,
});
