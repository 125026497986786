import { LocationConfig, PartnerData, VehicleCategoryData } from '@/services/prebooking';
import VehicleCategory from '@/types/vehicle-category';
import { MB_RENT_DEFAULT_CATEGORY } from '@/config/vehicle-categories';
import { mergeOfferIntoCategory, vehicleCategoryIsEnabled } from './vehicle-category';
import { Offer } from '@/types/offer';

// the algorithm assumes that vehicleCategoryData is sorted from smallest to largest category
// we search for the default category and return a list ordered by the fallback category hierarchy
// elements that are larger than the default have priority if the default is not available
// once the largest elements are used up, we fallback to the smaller categories ordered from largest to smallest
const getOrderedVehicleCategoryData = (
  vehicleCategoryData: VehicleCategoryData[],
  defaultVehicleCategory: VehicleCategory
) => {
  const defaultIndex = vehicleCategoryData.findIndex(data => data.vehicleCategory === defaultVehicleCategory);
  if (!defaultIndex) return vehicleCategoryData;
  const largerElements = vehicleCategoryData.slice(defaultIndex);
  const smallerElements = vehicleCategoryData.slice(0, defaultIndex);
  return [...largerElements, ...smallerElements.reverse()];
};

const getEnabledCategories = (vehicleCategoryData: VehicleCategoryData[]) => {
  return vehicleCategoryData.filter(vehicleCategoryIsEnabled).map(categoryData => categoryData.vehicleCategory);
};

const getDefaultCategory = (
  vehicleCategories: VehicleCategoryData[],
  defaultVehicleCategory: VehicleCategory,
  offers: Offer[]
) => {
  const vehicleCategoriesWithOffer = vehicleCategories.map(category => mergeOfferIntoCategory(category, offers));
  const orderedVehicleCategories = getOrderedVehicleCategoryData(vehicleCategoriesWithOffer, defaultVehicleCategory);
  const enabledCategories = getEnabledCategories(orderedVehicleCategories);
  if (enabledCategories.includes(defaultVehicleCategory)) return defaultVehicleCategory;
  if (enabledCategories.length > 0) return enabledCategories[0];
  return null;
};

export const getDefaultShareNowCategory = (config: LocationConfig, vehicleCategories: VehicleCategoryData[], offers: Offer[]): VehicleCategory | null => {
  const defaultVehicleCategory = config.defaultVehicleCategory;
  return getDefaultCategory(vehicleCategories, defaultVehicleCategory, offers);
};

export const getDefaultMbRentCategory = (config: PartnerData, offers: Offer[]): VehicleCategory | null => {
  const defaultVehicleCategory = MB_RENT_DEFAULT_CATEGORY;
  const vehicleCategories = config.vehicleCategoryData;
  return getDefaultCategory(vehicleCategories, defaultVehicleCategory, offers);
};
