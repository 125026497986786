import React, { useContext } from 'react';
import { translate } from '@rental/prebooking-ui-utils';
import { Replacers } from '@/types/translate';

export const TranslationContext = React.createContext({});
TranslationContext.displayName = 'TranslationContext';

const useTranslation = () => {
  const translations = useContext(TranslationContext);
  return { translate: (key: string, replace: Replacers = {}) => translate(key, replace, translations) };
};

export default useTranslation;
