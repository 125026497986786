import { graphql, useStaticQuery } from 'gatsby';
import { LatLng } from '@/types/lat-lng';

export interface CityConfig {
  country: string;
  id: string;
  number: number;
  languages: { code: string; name: string }[];
  name: string;
  support: { phone: string };
  center: LatLng & { radius: number }; // used for google maps suggestion bias
}

export interface GatsbyPageData {
  files: { nodes: { base: string; publicURL: string }[] };
  cities: { nodes: CityConfig[] };
}

const getGatsbyPageData = (): GatsbyPageData =>
  useStaticQuery(graphql`
    {
      cities: allCitiesJson {
        nodes {
          id
          number
          name
          country
          support {
            phone
          }
          languages {
            code
            name
          }
          center {
            lat
            lng
            radius
          }
        }
      }
      files: allFile {
        nodes {
          base
          publicURL
        }
      }
    }
  `);

export default getGatsbyPageData;
