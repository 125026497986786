export const SKIP_ONBOADING = 'SKIP_ONBOADING';
export const SET_SHOW_ONBOARDING = 'SET_SHOW_ONBOARDING';

interface SkipOnboarding {
  type: typeof SKIP_ONBOADING;
  name: string;
}

interface SetShowOnboarding {
  type: typeof SET_SHOW_ONBOARDING;
  name: string;
  payload: boolean;
}

export type OnboardingActions = SkipOnboarding | SetShowOnboarding;
