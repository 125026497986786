import { VehicleCategoryData } from '@/services/prebooking';
import { Offer } from '@/types/offer';

export interface VehicleCategoryDataWithOffer extends VehicleCategoryData {
  offer?: Offer;
}

export const vehicleCategoryIsEnabled = (vehicleCategoryData: VehicleCategoryDataWithOffer) => {
  return (
    vehicleCategoryData.meetsAgeRequirement &&
    vehicleCategoryData.isBookable &&
    !vehicleCategoryIsSoldOut(vehicleCategoryData)
  );
};

export const SOLD_OUT_LABEL = 'SOLD_OUT';
export const vehicleCategoryIsSoldOut = (vehicleCategoryData: VehicleCategoryDataWithOffer) => {
  return vehicleCategoryData.offer === undefined || vehicleCategoryData.offer.label === SOLD_OUT_LABEL;
};

// pricing uses hyphens in category names
const normalizePricingCategory = (category: string) => category.replace('-', '');

export const findOfferForCategory = (offers: Offer[], vehicleCategoryData: VehicleCategoryData | undefined) => {
  const category = vehicleCategoryData?.vehicleCategory;
  return offers.find(o =>
    vehicleCategoryData?.buildSeries.includes(o.build_series) ||
    (o.car_category && normalizePricingCategory(o.car_category) === category),
  );
};

export const mergeOfferIntoCategory = (vehicleCategory: VehicleCategoryData, offers: Offer[]) => {
  const offer = findOfferForCategory(offers, vehicleCategory);
  return { ...vehicleCategory, offer };
};

export const validateVehicleCategory = (vehicleCategoryData: VehicleCategoryDataWithOffer | undefined) => {
  return vehicleCategoryData !== undefined && vehicleCategoryIsEnabled(vehicleCategoryData);
};
