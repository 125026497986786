import React, { FC } from 'react';
import loadable from '@loadable/component';

const Header = loadable(() => import('@/components/header/header'));
const AppHeader = loadable(() => import('@/components/app-header/app-header'));
const Footer = loadable(() => import('@/components/footer/footer'));

const Layout: FC<{ webView: boolean }> = ({ webView, children }) => {
  return <>
    {webView ? <AppHeader /> : <Header />}
    {children}
    {!webView && <Footer />}
  </>;
};

export default Layout;