import useBooking from '@/hooks/use-booking/use-booking';
import useSelectedOffer from '@/hooks/use-selected-offer/use-selected-offer';
import useMbRent from '@/hooks/use-mb-rent/use-mb-rent';
import { offerOptionIsIncluded } from '@/utils/offers';

const useSelectedPackages = () => {
  const { values } = useBooking();
  const { offer } = useSelectedOffer();
  const { active: mbRentActive } = useMbRent();

  if (mbRentActive) {
    return offer
      ? offer.additional_options.filter(option => values.mbr_extras.packages.some(value => value === option.identifier) || offerOptionIsIncluded(option))
      : [];
  }

  return offer
    ? offer.additional_options.filter(option => values.extras.packages.some(value => value === option.identifier) || offerOptionIsIncluded(option))
    : [];
};

export default useSelectedPackages;
