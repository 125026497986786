import React, {FC, ReactNode, useCallback, useContext, useEffect, useState} from 'react';

import usePageData from '@/hooks/use-data/use-data';
import useInitialDates from '@/hooks/use-initial-dates/use-initial-dates';
import {BookingFormData, flushCache, getCache, setCache} from '@/utils/global-cache';
import {getWebpackHash} from '@/utils/webpack-hash';
import {EXTRAS} from '@/config/extras';
import useLocationConfig from '@/hooks/use-location-config/use-location-config';
import {initialMbrTripData} from '@/components/forms/steps/mbr-trip/initial-data';
import useLanguage from '@/hooks/use-language/use-language';
import VehicleCategory from "@/types/vehicle-category";

const BookingContext = React.createContext({});
BookingContext.displayName = 'BookingContext';

export const BookingProvider: FC<{ children: ReactNode }> = ({children}) => {
  const {language} = useLanguage();
  const {locationConfig} = useLocationConfig();
  const partnerConfig = locationConfig.partner ? locationConfig.partner.partnerStation : null;
  const pageData = usePageData();
  const {startDate, endDate} = useInitialDates();
  const webpackHash = getWebpackHash();

  let sessionData = getCache();

  if (sessionData) {
    // Flush the cache if language or city has changed or when new version was deployed. The form will be
    // initialized with initial values to prevent mixing values from different
    // cities or languages.
    const versionExpired = sessionData.version !== webpackHash;
    if (sessionData.language !== pageData.language || sessionData.cityName !== pageData.cityName || versionExpired) {
      flushCache();
      sessionData = undefined;
    }
  }

  const params = new URLSearchParams(location.search);
  const paramsCategory = params.get('category')
  const vehicleType = paramsCategory ? paramsCategory.toUpperCase() as VehicleCategory : null
  const address = params.get('address') || ''
  const latLng = (params.get('lat') && params.get('lng')) ? {
    lat: Number(params.get('lat')),
    lng: Number(params.get('lng')),
  } : undefined;

  const initialState: BookingFormData = sessionData || {
    version: webpackHash,
    cityName: pageData.cityName,
    language: language,
    mileage: 0,
    completed: {
      service: false,
      trip: address !== "" && latLng !== undefined,
      category: vehicleType !== null,
      extras: false,
      personal: false,
    },
    service: {
      selectedService: null,
    },
    trip: {
      vehicleType,
      startDate,
      endDate,
      address,
      location: latLng,
    },
    extras: {
      packages: [],
      insurance: EXTRAS.DEFAULT_INSURANCE_IDENTIFIER,
    },
    personal: {
      firstName: '',
      lastName: '',
      email: '',
      consent: false,
    },
    mbr_trip: initialMbrTripData(partnerConfig),
    mbr_category: {
      vehicleType: null,
    },
    mbr_extras: {
      packages: [],
      insurance: EXTRAS.DEFAULT_INSURANCE_IDENTIFIER,
    },
    mbr_overview: {
      consent: false,
    },
  };

  const [state, setState] = useState(initialState);

  const context = {
    values: state,
    setValue: useCallback(
      (key: keyof BookingFormData, value: any) => {
        setState(prevState => {
          return {...prevState, [key]: value};
        });
      },
      [setState],
    ),
  };

  // Update the cache on state change.
  useEffect(() => {
    setCache(state);
  }, [state]);

  return <BookingContext.Provider value={context}>{children}</BookingContext.Provider>;
};

const useBooking = () => {
  const context = useContext(BookingContext);
  return context as { setValue: (key: string, value: any) => void; values: BookingFormData };
};

export default useBooking;
