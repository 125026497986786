import React, { useEffect } from 'react';
import { useUsercentrics } from '@/hooks/use-usercentrics/use-usercentrics';
import * as SentryBrowser from '@sentry/browser';

const Sentry = () => {
  const { sentry: sentryIsAllowed } = useUsercentrics();

  useEffect(() => {
    SentryBrowser.init({
      dsn: process.env.SENTRY_ENDPOINT,
      enabled: !!process.env.SENTRY_ENDPOINT && sentryIsAllowed,
      environment: process.env.URL,
    });
  }, [sentryIsAllowed]);

  window.Sentry = SentryBrowser;

  return null;
};

export default Sentry;