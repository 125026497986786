import parseISO from 'date-fns/parseISO';

// specify fields which should be parsed as date
const DATE_KEYS = ['startDate', 'endDate'];

const reviverFunction = (key: string, value: any) => {
  if (DATE_KEYS.includes(key)) {
    return parseISO(value);
  }
  return value;
};

const storageWrapper = (storageProvider: Storage) => {
  return {
    getItem(key: string, fallback: any = undefined) {
      const result = storageProvider.getItem(key);
      if (result === null) return fallback;
      try {
        return JSON.parse(result, reviverFunction);
      } catch (e) {
        return result;
      }
    },

    setItem(key: string, value: any) {
      storageProvider.setItem(key, JSON.stringify(value));
    },

    removeItem(key: string) {
      storageProvider.removeItem(key);
    },
  };
};

// window is not accessible during gatsby build
const placeholderStorage = {
  getItem: () => undefined,
  setItem: () => {},
  removeItem: () => {},
};

export const local = typeof window !== 'undefined' && window.localStorage ? storageWrapper(window.localStorage) : placeholderStorage;
export const session = typeof window !== 'undefined' && window.sessionStorage ? storageWrapper(window.sessionStorage) : placeholderStorage;
