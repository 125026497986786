import { createInstance } from '@/services/axios-instance';
import { PaymentProfile } from '@/types/payment-profile';

export const PAYMENT_SERVICE_BASE_URL = '/api/billing/customer';

const instance = createInstance({
  baseURL: PAYMENT_SERVICE_BASE_URL,
});

interface PaymentProfilesResponse {
  body: {
    paymentProfiles: PaymentProfile[];
  };
}

export const getPaymentValidProfiles = async (token: string): Promise<PaymentProfilesResponse> => {
  const response = await instance.get(`/payment-profile-summary`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
