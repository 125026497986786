import React from 'react';

import styles from './sn-spinner.module.scss';

const Spinner = () => (
  <div className={styles.root}>
    <svg width="54" height="54" viewBox="0 0 36 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M15.0686 28.75C14.8839 28.75 14.6977 28.6936 14.5317 28.5898C10.5556 25.9917 8.94898 20.8251 10.709 16.3121C12.4706 11.7984 17.0909 9.22838 21.6975 10.2067C22.2437 10.3217 22.5903 10.8741 22.4793 11.4343C22.3681 11.999 21.8374 12.3511 21.2966 12.238C17.6384 11.4613 13.9697 13.5056 12.5712 17.0877C11.1727 20.669 12.4494 24.769 15.6032 26.8356C16.0739 27.138 16.2141 27.7778 15.9182 28.2658C15.7242 28.5751 15.4012 28.75 15.0686 28.75Z"
          fill="currentColor" />
        <path
          d="M20.2632 30C19.6182 30 18.9599 29.9326 18.3014 29.7888C17.7595 29.6738 17.4089 29.1259 17.5208 28.5699C17.6351 28.0052 18.1646 27.641 18.7063 27.7573C22.3631 28.5322 26.0311 26.4989 27.4249 22.9144C28.8273 19.3258 27.5509 15.2255 24.3982 13.1673C23.9235 12.8563 23.7867 12.2164 24.0876 11.734C24.3795 11.2519 24.9982 11.1016 25.4649 11.4126C29.4442 14.0112 31.0516 19.1764 29.2906 23.6922C27.7773 27.561 24.1647 30 20.263 30H20.2632Z"
          fill="currentColor" />
      </g>
      <g>
        <path
          d="M23.5183 25C23.2403 25 22.9636 24.8825 22.7651 24.6506C22.3957 24.2181 22.4381 23.5597 22.8559 23.1862C24.1782 21.9837 24.6012 20.0599 23.9154 18.3824C23.2297 16.7132 21.5883 15.689 19.8371 15.8457C19.2842 15.8939 18.7988 15.4737 18.7535 14.9021C18.7077 14.3227 19.1172 13.8195 19.6696 13.7724C22.2908 13.5446 24.7352 15.071 25.766 17.5746C26.7883 20.0685 26.1592 22.9538 24.1805 24.7443C23.9869 24.921 23.7565 24.9997 23.5183 24.9997V25Z"
          fill="currentColor" />
        <path
          d="M19.8138 26.25C17.3964 26.25 15.195 24.7653 14.2352 22.433C13.2083 19.9284 13.8461 17.0477 15.8203 15.2604C16.2384 14.8782 16.8707 14.9227 17.2357 15.3547C17.6009 15.7873 17.5626 16.4373 17.1448 16.8193C15.8229 18.0221 15.3971 19.9463 16.0856 21.6158C16.7713 23.2914 18.4132 24.3087 20.1586 24.16C20.7179 24.1038 21.1971 24.5345 21.2465 25.106C21.2923 25.6774 20.8828 26.1801 20.3304 26.2272C20.1562 26.2452 19.9823 26.2497 19.8133 26.2497L19.8138 26.25Z"
          fill="currentColor" />
      </g>

    </svg>
  </div>
);

export default Spinner;
