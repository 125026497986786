import useMbRent from '@/hooks/use-mb-rent/use-mb-rent';
import useBooking from '@/hooks/use-booking/use-booking';

// returns selected start and end date depending on the selected flow
const useSelectedDates = () => {
  const { values } = useBooking();
  const { active: mbRentActive } = useMbRent();
  const { startDate, endDate } = mbRentActive ? values.mbr_trip : values.trip;
  return { startDate, endDate };
};

export default useSelectedDates;
