import { addDays, endOfTomorrow } from 'date-fns';
import { PartnerStationConfig } from '@/services/prebooking';
import { utcToZonedTime } from 'date-fns-tz';

interface MbrTripData {
  startDate: Date;
  endDate: Date;
}

export const initialMbrTripData = (config: PartnerStationConfig | null): MbrTripData => {
  const startDate = config ? utcToZonedTime(config.earliestPossiblePickup, config.timeZone) : endOfTomorrow();
  return {
    startDate,
    endDate: addDays(startDate, 1),
  };
};
