// used to save and restore form data in session storage

import { session } from './storage';
import { LatLng } from '@/types/lat-lng';
import VehicleCategory from '@/types/vehicle-category';

const CACHE_KEY = 'sn.ltcs.cache';
const isClient = typeof window !== 'undefined';

const SESSION_LOCATION = 'sn.ltcs.location';
export const cacheSessionLocation = (location: Partial<Location>) => {
  const url = location.pathname ? `${location.pathname.replace(process.env.GATSBY_PATH_PREFIX || '', '')}${location.search}` : undefined;
  session.setItem(SESSION_LOCATION, url);
};
export const getCachedSessionLocation = (): string | undefined => session.getItem(SESSION_LOCATION);
export const clearCachedSessionLocation = () => session.removeItem(SESSION_LOCATION);

export interface BookingFormData {
  version: string | null;
  cityName: string;
  language: string;
  mileage: number;
  completed: {
    service: boolean;
    trip: boolean;
    category: boolean;
    extras: boolean;
    personal: boolean;
  };
  service: {
    selectedService: string | null;
  };
  trip: {
    vehicleType: VehicleCategory | null;
    startDate: Date;
    endDate: Date;
    address: string;
    location: LatLng | undefined;
  };
  extras: {
    packages: string[];
    insurance: string;
  };
  personal: {
    firstName: string;
    lastName: string;
    email: string;
    consent: boolean;
  };
  mbr_trip: {
    startDate: Date;
    endDate: Date;
  };
  mbr_category: {
    vehicleType: VehicleCategory | null;
  };
  mbr_extras: {
    packages: string[];
    insurance: string;
  };
  mbr_overview: {
    consent: boolean;
  };
}

export const setCache = (state: any): void => {
  if (isClient) {
    session.setItem(CACHE_KEY, state);
  }
};

const getSessionData = (): BookingFormData | undefined => session.getItem(CACHE_KEY);

export const getCache = (): BookingFormData | undefined => {
  return isClient ? getSessionData() : undefined;
};

export const flushCache = (): void => {
  if (isClient) {
    session.removeItem(CACHE_KEY);
    clearCachedSessionLocation();
  }
};
