export const OPEN_PRICE_DETAILS = 'OPEN_PRICE_DETAILS';
export const CLOSE_PRICE_DETAILS = 'CLOSE_PRICE_DETAILS';
export const OPEN_PAYMENT_PROFILES = 'OPEN_PAYMENT_PROFILES';
export const CLOSE_PAYMENT_PROFILES = 'CLOSE_PAYMENT_PROFILES';

interface OpenPriceDetails {
  type: typeof OPEN_PRICE_DETAILS;
}

interface ClosePriceDetails {
  type: typeof CLOSE_PRICE_DETAILS;
}

interface OpenPaymentProfiles {
  type: typeof OPEN_PAYMENT_PROFILES;
}

interface ClosePaymentProfiles {
  type: typeof CLOSE_PAYMENT_PROFILES;
}

export type FlyOutsActions = OpenPriceDetails | ClosePriceDetails | OpenPaymentProfiles | ClosePaymentProfiles;
