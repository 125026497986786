export const IDENTIFIERS = {
  SHIELD: 'shield',
  SHIELD_PLUS: 'shield-plus',
  COFFEE: 'coffee',
  ARROWS: 'arrows',
  ADDITIONAL_DRIVER: 'additional-driver'
};

export const EXTRAS = {
  DEFAULT_INSURANCE_IDENTIFIER: IDENTIFIERS.SHIELD,
  INSURANCE_IDENTIFIERS: [IDENTIFIERS.SHIELD, IDENTIFIERS.SHIELD_PLUS],
  VALID_EXTRAS: [IDENTIFIERS.SHIELD, IDENTIFIERS.SHIELD_PLUS, IDENTIFIERS.COFFEE, IDENTIFIERS.ARROWS, IDENTIFIERS.ADDITIONAL_DRIVER],
};
