import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { LOAD_STATUS } from '@/store/load-status';
import { getVehicleCategories, VehicleCategoryData } from '@/services/prebooking';
import useSelectedDates from '@/hooks/use-selected-dates/use-selected-dates';
import useCity from '@/hooks/use-city/use-city';
import {getJwt} from "@rental/prebooking-ui-utils";
import {format} from "date-fns";

const VehiclesContext = createContext({});

interface Props {
  webView: boolean;
}

const VehiclesProvider: FC<Props> = ({ webView, children }) => {
  const [contextData, setContextData] = useState<{ vehicleCategories: VehicleCategoryData[], loadStatus: LOAD_STATUS }>({
    vehicleCategories: [],
    loadStatus: LOAD_STATUS.NOT_LOADED,
  });

  const { startDate } = useSelectedDates();
  const { city: { number: locationId } } = useCity();

  const date = format(startDate, "yyyy-MM-dd");
  useEffect(() => {
    const getVehicles = async () => {
      setContextData((data) => ({ ...data, loadStatus: LOAD_STATUS.LOADING }));
      const jwt = webView ? await getJwt() || null : null;
      try {
        const vehicles = await getVehicleCategories(Number(locationId), { startDate: date, jwt });
        setContextData({ vehicleCategories: vehicles, loadStatus: LOAD_STATUS.LOADED });
      } catch (e) {
        setContextData((data) => ({ ...data, loadStatus: LOAD_STATUS.FAILED }));
      }
    };
    getVehicles();
  }, [date, locationId, webView]);

  return <VehiclesContext.Provider value={contextData}>{children}</VehiclesContext.Provider>;
};

export const useSnVehicleCategories = () => {
  return useContext(VehiclesContext) as { vehicleCategories: VehicleCategoryData[], loadStatus: LOAD_STATUS };
};

export default VehiclesProvider;
