import useBooking from '@/hooks/use-booking/use-booking';
import { SERVICE_MB_RENT } from '@/constants/services';
import useLocationConfig from '@/hooks/use-location-config/use-location-config';

const useMbRent = () => {
  const { values } = useBooking();
  const {
    locationConfig: { partner: partnerData },
  } = useLocationConfig();

  const enabled = partnerData !== null;
  const active = values.service.selectedService === SERVICE_MB_RENT;

  return { enabled, active };
};

export default useMbRent;
