import { LOAD_STATUS } from '../load-status';
import { OfferActions, SET_OFFER, SET_OFFER_LOAD_STATUS } from './types';
import { Offer } from '@/types/offer';

interface OfferState {
  data: Offer[];
  loadStatus: LOAD_STATUS;
}

const initialState: OfferState = {
  data: [],
  loadStatus: LOAD_STATUS.NOT_LOADED,
};

export const offerReducer = (state = initialState, action: OfferActions) => {
  switch (action.type) {
    case SET_OFFER:
      return { ...state, data: action.payload };
    case SET_OFFER_LOAD_STATUS:
      return { ...state, loadStatus: action.payload };
    default:
      return state;
  }
};
