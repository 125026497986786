import { KNOWN_VEHICLE_CATEGORIES } from '@/config/vehicle-categories';
import useLocationConfig from '@/hooks/use-location-config/use-location-config';
import useMbRent from '@/hooks/use-mb-rent/use-mb-rent';
import useOffers from '@/hooks/use-offers/use-offers';
import { mergeOfferIntoCategory, vehicleCategoryIsEnabled } from '@/utils/vehicle-category';
import React from 'react';
import { useSnVehicleCategories } from '@/hooks/use-vehicle-categories/use-sn-vehicle-categories';

const useVehicleCategories = () => {
  const { vehicleCategories: snVehicleCategories, loadStatus } = useSnVehicleCategories();
  const { locationConfig } = useLocationConfig();
  const { active: mbRentActive } = useMbRent();
  const { offers } = useOffers();

  const vehicleCategories =
    mbRentActive && locationConfig.partner
      ? locationConfig.partner.vehicleCategoryData
      : snVehicleCategories;

  const allVehicleCategories = vehicleCategories
    .filter(vehicleData => KNOWN_VEHICLE_CATEGORIES.includes(vehicleData.vehicleCategory))
    .map(c => mergeOfferIntoCategory(c, offers)) || [];

  return {
    loadStatus,
    vehicleCategories: allVehicleCategories,
    enabledVehicleCategories: allVehicleCategories.filter(vehicleCategoryIsEnabled),
    disabledVehicleCategories: allVehicleCategories.filter(c => !vehicleCategoryIsEnabled(c)),
  };
};

export default useVehicleCategories;
