import React, { createContext, FunctionComponent, useContext } from 'react';

import { useFetchOffer } from '@/hooks/use-fetch-offer/use-fetch-offer';
import useSelectedInsurance from '@/hooks/use-selected-insurance/use-selected-insurance';
import useSelectedPackages from '@/hooks/use-selected-packages/use-selected-packages';
import useSelectedOffer from '@/hooks/use-selected-offer/use-selected-offer';
import { Offer } from '@/types/offer';

const PricingContext = createContext({});

export const PricingProvider: FunctionComponent = ({ children }) => {
  const selectedInsurance = useSelectedInsurance();
  const selectedPackages = useSelectedPackages();
  const { offer, offerError, driverProtectionFee } = useSelectedOffer();
  useFetchOffer();

  const calculatePricing = () => {
    const tripPrice = offer ? offer.price : 0;

    const insurancePrice = selectedInsurance ? selectedInsurance.price : 0;

    const packagesPrices = selectedPackages.reduce((acc, curr) => acc + curr.price, 0);

    const components = {
      trip: tripPrice,
      insurance: insurancePrice,
      extras: packagesPrices,
      dpf: driverProtectionFee,
    };

    const total = components.trip + components.insurance + components.extras + components.dpf;

    return {
      components,
      total,
      offerError,
      offer,
    };
  };

  const context = calculatePricing();

  return <PricingContext.Provider value={context}>{children}</PricingContext.Provider>;
};

export default () =>
  useContext(PricingContext) as {
    components: { trip: number; insurance: number; extras: number };
    total: number;
    offerError: boolean;
    offer: Offer | undefined;
  };
