import useBooking from '@/hooks/use-booking/use-booking';
import useSelectedOffer from '@/hooks/use-selected-offer/use-selected-offer';
import useMbRent from '@/hooks/use-mb-rent/use-mb-rent';

const useSelectedInsurance = () => {
  const { offer } = useSelectedOffer();
  const { values } = useBooking();
  const { active: mbRentActive } = useMbRent();

  if (mbRentActive) {
    return offer && offer.additional_options.find(options => options.identifier === values.mbr_extras.insurance);
  }

  return offer && offer.additional_options.find(options => options.identifier === values.extras.insurance);
};

export default useSelectedInsurance;
