import React, { useEffect } from 'react';

// this component activates Google Analytics (GA script is located in html.tsx)
// this is necessary when Usercentrics is not present (webviews)
const GoogleAnalytics = () => {
  useEffect(() => {
    const script = document.getElementById('google-analytics');
    if (script) {
      const analytics = document.createElement('script');
      analytics.type = 'text/javascript';
      analytics.defer = true;
      analytics.innerText = script.innerText;
      document.head.appendChild(analytics);
      document.head.removeChild(script);
    }
  }, []);
  return null;
};

export default GoogleAnalytics;