import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCity from '@/hooks/use-city/use-city';

const UsercentricsContext = React.createContext({ sentry: false });

UsercentricsContext.displayName = 'UsercentricsContext';

interface UseUsercentricsProviderProps {
  webView: boolean,
}

const getDataSettingsId = (countryISO: string) => {
  switch (countryISO.toUpperCase()) {
    case 'HU':
      return 'jwbPyDd2q';
    case 'DK':
      return '7Y7k8b0Z3';
    default:
      return 'O9vNYWD7';
  }
};

// provider used to check if user has given consent for third party libraries (sentry)
export const UsercentricsProvider: FunctionComponent<UseUsercentricsProviderProps> = ({ webView, children }) => {

  // sentry is always enabled in webview
  const [consent, setConsent] = useState({ sentry: webView });
  const { city } = useCity();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && !webView) {
      const script = document.createElement('script');
      script.src = 'https://app.usercentrics.eu/browser-ui/latest/bundle.js';
      script.defer = true;
      script.id = 'usercentrics-cmp';
      script.setAttribute('data-settings-id', (getDataSettingsId(city.country)));
      document.body.appendChild(script);
      const { UC_UI } = window;
      if (UC_UI && UC_UI.isInitialized()) {
        handleInitialized();
      } else {
        window.addEventListener('UC_UI_INITIALIZED', handleInitialized);
      }

      return () => {
        window.removeEventListener('UC_UI_INITIALIZED', handleInitialized);
        window.removeEventListener('ucChangeEvents', handleInitialized);
      };
    }
  }, []);

  const handleInitialized = () => {
    handleConsentStatus();
    window.removeEventListener('UC_UI_INITIALIZED', handleInitialized);
    window.addEventListener('ucChangeEvents', handleConsentStatus);
  };

  const handleConsentStatus = (event?: any) => {
    if (event) {
      // Get consent status from change event
      setConsent({ ...consent, sentry: event.detail?.Sentry || false });
    } else {
      // Get consent status on page load
      const userConsent = getInitialUserConsent();
      setConsent({ ...consent, sentry: userConsent.Sentry || false });
    }
  };

  const getInitialUserConsent = () => {
    const dataLayerItems = window.dataLayer || [];
    const implicitConsentStatus = dataLayerItems.find((item) => item.event == 'consent_status' && item.type === 'implicit') || {};
    const explicitConsentStatus = dataLayerItems.find((item) => item.event == 'consent_status' && item.type === 'explicit') || {};
    return { ...implicitConsentStatus, ...explicitConsentStatus };
  };

  return <UsercentricsContext.Provider value={consent}>
    {children}
  </UsercentricsContext.Provider>;
};

export const useUsercentrics = () => {
  return useContext(UsercentricsContext);
};