import { PaymentProfile } from '@/types/payment-profile';
import { LOAD_STATUS } from '../load-status';

export const SET_PAYMENT_PROFILES = 'SET_PAYMENT_PROFILES';
export const SET_PAYMENT_PROFILES_LOAD_STATUS = 'SET_PAYMENT_PROFILES_LOAD_STATUS';
export const SET_SELECTED_PAYMENT_PROFILE_ID = 'SET_SELECTED_PAYMENT_PROFILE_ID';

interface SetPaymentProfiles {
  type: typeof SET_PAYMENT_PROFILES;
  payload: PaymentProfile[];
}

interface SetPaymentProfilesLoadStatus {
  type: typeof SET_PAYMENT_PROFILES_LOAD_STATUS;
  payload: LOAD_STATUS;
}

interface SetSelectedPaymentProfileId {
  type: typeof SET_SELECTED_PAYMENT_PROFILE_ID;
  payload: number;
}

export type PaymentProfilesActions =
  SetPaymentProfiles
  | SetPaymentProfilesLoadStatus
  | SetSelectedPaymentProfileId;
