import { addDays } from 'date-fns';
import useLocationConfig from '@/hooks/use-location-config/use-location-config';
import { isValidDateObject, roundToNextHalfHour } from '@/utils/date-helper';
import { useMemo } from 'react';

const getStartDate = (params: URLSearchParams, earliestPrebookingPossibleAt: string) => {
  const startDateParam = params.get('startDate');
  if (startDateParam !== null && isValidDateObject(new Date(startDateParam))) {
    return new Date(startDateParam);
  }
  return roundToNextHalfHour(new Date(earliestPrebookingPossibleAt));
};

const getEndDate = (params: URLSearchParams, startDate: Date) => {
  const endDateParam = params.get('endDate');
  if (endDateParam !== null && isValidDateObject(new Date(endDateParam))) {
    return new Date(endDateParam);
  }
  return addDays(startDate, 1);
};

const useInitialDates = () => {
  const { locationConfig } = useLocationConfig();

  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    const startDate = getStartDate(params, locationConfig.earliestPrebookingPossibleAt);
    const endDate = getEndDate(params, startDate);
    return {
      startDate,
      endDate,
    };
  }, [locationConfig.earliestPrebookingPossibleAt]);
};

export default useInitialDates;
