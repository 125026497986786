declare global {
  const __webpack_hash__: string; // generated through webpack in dev
  const ___webpackCompilationHash: string; // generated through webpack in prod
}

export const getWebpackHash = (): string | null => {
  const webpackCompilationHash = typeof ___webpackCompilationHash !== 'undefined' ? ___webpackCompilationHash : null;
  const webpackHash = typeof __webpack_hash__ !== 'undefined' ? __webpack_hash__ : null;
  return webpackCompilationHash || webpackHash || null;
};
