import axios, { AxiosError, AxiosResponse } from 'axios';

class NormalizedError extends Error {
  private status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export const createInstance = ({ baseURL = '' }) => {
  const instance = axios.create({
    baseURL
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response) {
        const { status } = error.response;
        const normalizedError = new NormalizedError(error.message, status);
        return Promise.reject(normalizedError);
      } else if (axios.isCancel(error)) {
        return new Promise(() => {}); // swallow cancelled api calls
      } else {
        return Promise.reject(error);
      }
    }
  );

  return instance;
};
