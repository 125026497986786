import { local, session } from '@/utils/storage';
import { OnboardingActions, SET_SHOW_ONBOARDING, SKIP_ONBOADING } from './types';

const initialState = (onboardingSkippedKey: string, showOnboardingKey: string) => ({
  onboardingSkipped: !!session.getItem(onboardingSkippedKey),
  showOnboarding: local.getItem(showOnboardingKey, true),
});

const createOnboardingReducer = (reducerName: string, onboardingSkippedKey: string, showOnboardingKey: string) => (
  state = initialState(onboardingSkippedKey, showOnboardingKey),
  action: OnboardingActions
) => {
  if (action.name !== reducerName) return state;
  switch (action.type) {
    case SKIP_ONBOADING:
      session.setItem(onboardingSkippedKey, true);
      return { ...state, onboardingSkipped: true };
    case SET_SHOW_ONBOARDING:
      local.setItem(showOnboardingKey, action.payload);
      return { ...state, showOnboarding: action.payload };
    default:
      return state;
  }
};

export default createOnboardingReducer;
