import { getPaymentValidProfiles } from '@/services/payment';
import { getDefaultPaymentProfile } from './helpers';
import { LOAD_STATUS } from '../load-status';
import {
  PaymentProfilesActions,
  SET_PAYMENT_PROFILES,
  SET_PAYMENT_PROFILES_LOAD_STATUS,
  SET_SELECTED_PAYMENT_PROFILE_ID,
} from './types';
import { PaymentProfile } from '@/types/payment-profile';
import { Dispatch } from 'redux';
import {getJwt} from "@rental/prebooking-ui-utils";

export const setPaymentProfiles = (paymentProfiles: PaymentProfile[]): PaymentProfilesActions => ({
  type: SET_PAYMENT_PROFILES,
  payload: paymentProfiles,
});

export const setPaymentProfilesLoadStatus = (loadStatus: LOAD_STATUS): PaymentProfilesActions => ({
  type: SET_PAYMENT_PROFILES_LOAD_STATUS,
  payload: loadStatus,
});

export const setSelectedPaymentProfileId = (paymentProfileId: number): PaymentProfilesActions => ({
  type: SET_SELECTED_PAYMENT_PROFILE_ID,
  payload: paymentProfileId,
});

export const fetchPaymentProfiles = () => async (dispatch: Dispatch) => {
  dispatch(setPaymentProfilesLoadStatus(LOAD_STATUS.LOADING));
  const token = await getJwt();
  if (token) {
    try {
      const {
        body: { paymentProfiles },
      } = await getPaymentValidProfiles(token);
      const selectedPaymentProfile = getDefaultPaymentProfile(paymentProfiles);
      dispatch(setPaymentProfiles(paymentProfiles));
      if (selectedPaymentProfile) dispatch(setSelectedPaymentProfileId(selectedPaymentProfile.paymentProfileId));
      return dispatch(setPaymentProfilesLoadStatus(LOAD_STATUS.LOADED));
    } catch (error) {
      console.log(error);
      dispatch(setPaymentProfiles([]));
      return dispatch(setPaymentProfilesLoadStatus(LOAD_STATUS.FAILED));
    }
  }
};
