import { LOAD_STATUS } from '../load-status';
import { Offer } from '@/types/offer';

export const SET_OFFER = 'SET_OFFER';
export const SET_OFFER_LOAD_STATUS = 'SET_OFFER_LOAD_STATUS';

interface SetOffer {
  type: typeof SET_OFFER;
  payload: Offer[];
}

interface SetOfferLoadStatus {
  type: typeof SET_OFFER_LOAD_STATUS;
  payload: LOAD_STATUS;
}

export type OfferActions = SetOffer | SetOfferLoadStatus;
