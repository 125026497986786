enum VehicleCategory {
  XS = 'XS',
  XSE = 'XSE',
  XSC = 'XSC',
  S = 'S',
  SE = 'SE',
  SC = 'SC',
  M = 'M',
  ME = 'ME',
  MC = 'MC',
  L = 'L',
  LE = 'LE',
  LC = 'LC',
  XL = 'XL',
}

export default VehicleCategory;
