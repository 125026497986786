import {
  CLOSE_PAYMENT_PROFILES,
  CLOSE_PRICE_DETAILS,
  OPEN_PAYMENT_PROFILES,
  OPEN_PRICE_DETAILS,
  FlyOutsActions,
} from './types';

interface FlyOutsState {
  priceDetailsOpen: boolean;
  paymentProfilesOpen: boolean;
}

const initialState: FlyOutsState = {
  priceDetailsOpen: false,
  paymentProfilesOpen: false,
};

export const flyOutsReducer = (state = initialState, action: FlyOutsActions) => {
  switch (action.type) {
    case OPEN_PRICE_DETAILS:
      return { ...state, priceDetailsOpen: true };
    case CLOSE_PRICE_DETAILS:
      return { ...state, priceDetailsOpen: false };
    case OPEN_PAYMENT_PROFILES:
      return { ...state, paymentProfilesOpen: true };
    case CLOSE_PAYMENT_PROFILES:
      return { ...state, paymentProfilesOpen: false };
    default:
      return state;
  }
};
