import { CancelToken } from 'axios';
import { createInstance } from '@/services/axios-instance';
import { withPrefix } from 'gatsby';
import { Offer } from '@/types/offer';

const instance = createInstance({
  baseURL: withPrefix('/api/booking'),
});

export interface OfferRequest {
  location: string;
  build_series: string[];
  payment_profile_id?: number | null;
  pickup_at: string | Date;
  dropoff_at: string | Date;
  locale: string;
  token?: string;
  partner?: string;
}

interface OfferResponse {
  offers: Offer[];
}

export const getOffers = async (offerRequest: OfferRequest, cancelToken: CancelToken): Promise<OfferResponse> => {
  const response = await instance.post('/offers', offerRequest, { cancelToken });
  return response.data;
};
