import {
  CLOSE_PAYMENT_PROFILES,
  CLOSE_PRICE_DETAILS,
  OPEN_PAYMENT_PROFILES,
  OPEN_PRICE_DETAILS,
  FlyOutsActions,
} from './types';

export const openPriceDetails = (): FlyOutsActions => ({
  type: OPEN_PRICE_DETAILS,
});

export const closePriceDetails = (): FlyOutsActions => ({
  type: CLOSE_PRICE_DETAILS,
});

export const openPaymentProfiles = (): FlyOutsActions => ({
  type: OPEN_PAYMENT_PROFILES,
});

export const closePaymentProfiles = (): FlyOutsActions => ({
  type: CLOSE_PAYMENT_PROFILES,
});
