import { LOAD_STATUS } from '@/store/load-status';
import useOffers from '@/hooks/use-offers/use-offers';
import { VehicleCategoryData } from '@/services/prebooking';
import { findOfferForCategory } from '@/utils/vehicle-category';

const DURATION_TAG = 'package_duration_minutes';
const INCLUSIVE_DISTANCE_TAG = 'package_inclusive_distance';
const DISTANCE_PRICE_TAG = 'distance_price';
const DRIVER_PROTECTION_FEE_TAG = 'dpf_price';

const useOffer = (vehicleCategoryData: VehicleCategoryData | undefined) => {
  const { offers, loadStatus } = useOffers();
  const offer = findOfferForCategory(offers, vehicleCategoryData)
  const offerError = loadStatus === LOAD_STATUS.FAILED;
  const offerProperties = offer ? offer.properties : [];

  const findOfferProperty = (tag: string) => offerProperties.find(property => property.tags.includes(tag));

  const durationProperty = findOfferProperty(DURATION_TAG);
  const daysToBook = Math.max(durationProperty ? Math.trunc(Number(durationProperty.value) / 1440) : 1, 1);

  const inclusiveDistance = findOfferProperty(INCLUSIVE_DISTANCE_TAG);
  const includedMileage = inclusiveDistance ? inclusiveDistance.value : '0 km';

  const distancePrice = findOfferProperty(DISTANCE_PRICE_TAG);
  const additionalMileage = distancePrice ? distancePrice.value : '';
  const pricePerKm = distancePrice ? distancePrice.price : 0;

  const dpfPrice = findOfferProperty(DRIVER_PROTECTION_FEE_TAG);
  const driverProtectionFee = dpfPrice && dpfPrice.price ? dpfPrice.price : 0;

  return {
    daysToBook,
    offer,
    offerError,
    includedMileage,
    additionalMileage,
    driverProtectionFee,
    pricePerKm,
  };
};

export default useOffer;
