import { usePageContext } from '@/components/page-context-provider/page-context-provider';
import React, { useContext, useEffect, useState } from 'react';
import Spinner from '@/components/common/sn-spinner';
import { getLocationConfig, LocationConfig } from '@/services/prebooking';
import {findLocationByName, getJwt} from '@rental/prebooking-ui-utils';

const DataContext = React.createContext({});

export const AutomaticDataProvider = ({ children }) => {
  const [contextData, setContextData] = useState<{ locationConfig: LocationConfig | null, jwt: string | undefined }>({
    locationConfig: null,
    jwt: undefined,
  });
  const pathParams = usePageContext();

  useEffect(() => {
    const fetchLocationConfig = async () => {
      setContextData({ ...contextData, locationConfig: null });
      const jwt = pathParams.webView ? await getJwt() : undefined;
      const config = await getLocationConfig(findLocationByName(pathParams.cityName).locationId);
      if (!config.isDevelopment || process.env.GATSBY_DEVELOPMENT) {
        setContextData({
          locationConfig: config,
          jwt,
        });
      }
    }
    fetchLocationConfig()
  }, [pathParams.cityName, pathParams.language, pathParams.webView]);

  const data = {
    locationConfig: contextData.locationConfig,
    jwt: contextData.jwt,
  };

  return <DataContext.Provider value={data}>{contextData.locationConfig ? children :
    <Spinner />}</DataContext.Provider>;
};

const usePageData = () => {
  const pageContext = usePageContext();
  return {
    webView: pageContext.webView,
    cityName: pageContext.cityName,
    language: pageContext.language,
  };
};

export const useConfig = () => {
  const data = useContext(DataContext);
  return data as { locationConfig: LocationConfig };
};

// don't use this hook if you want a fresh jwt
export const useOldJwt = () => {
  const data = useContext(DataContext) as { jwt: string | undefined };
  return data.jwt;
};

export default usePageData;
