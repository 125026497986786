import { LocationZone } from '@/services/location';

export const SET_AIRPORT_ZONES = 'SET_AIRPORT_ZONES';

interface SetAirportZones {
  type: typeof SET_AIRPORT_ZONES;
  payload: LocationZone[];
}

export type AirportZonesActions = SetAirportZones;
