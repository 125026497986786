import usePageData from '@/hooks/use-data/use-data';
import useCities from '@/hooks/use-cities/use-cities';

const useCity = () => {
  const pageData = usePageData();
  const { cities } = useCities();
  const city = cities.find(city => city.id === pageData.cityName)!;
  return { city };
};

export default useCity;
