import { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { createCancelToken } from '@/services/prebooking';
import { useDispatch } from 'react-redux';
import { fetchPaymentProfiles } from '@/store/payment-profiles/actions';
import { LOAD_STATUS } from '@/store/load-status';
import { getOffers } from '@/services/offers';
import { setOffer, setOfferLoadStatus } from '@/store/offer/actions';
import { CancelTokenSource } from 'axios';
import useCity from '@/hooks/use-city/use-city';
import usePageData from '@/hooks/use-data/use-data';
import useVehicleCategories from '@/hooks/use-vehicle-categories/use-vehicle-categories';
import useMbRent from '@/hooks/use-mb-rent/use-mb-rent';
import useLanguage from '@/hooks/use-language/use-language';
import {DEFAULT_LANGUAGE, getJwt, SUPPORTED_LANGUAGES} from '@rental/prebooking-ui-utils';
import useSelectedDates from '@/hooks/use-selected-dates/use-selected-dates';
import useBooking from '@/hooks/use-booking/use-booking';
import useSelectedCategory from '@/hooks/use-selected-category/use-selected-category';
import { getDefaultMbRentCategory, getDefaultShareNowCategory } from '@/utils/get-default-category';
import useLocationConfig from '@/hooks/use-location-config/use-location-config';
import { mergeOfferIntoCategory, validateVehicleCategory } from '@/utils/vehicle-category';
import usePaymentProfiles from '@/hooks/use-payment-profiles/use-payment-profiles';
import { Offer } from '@/types/offer';

export const useFetchOffer = () => {
  const { webView } = usePageData();
  const { language } = useLanguage();
  const { vehicleCategories, loadStatus: vehicleLoadStatus } = useVehicleCategories();
  const buildSeries = vehicleCategories.map(c => c.buildSeries[0]);
  const lastOfferCancelSourceRef = useRef<null | CancelTokenSource>(null);
  const dispatch = useDispatch();
  const { selectedPaymentProfile, paymentProfilesLoadStatus } = usePaymentProfiles();
  const paymentProfilesLoaded = paymentProfilesLoadStatus === LOAD_STATUS.LOADED;
  const setOfferState = (offer: Offer[]) => dispatch(setOffer(offer));
  const setLoadStatus = (loadStatus: LOAD_STATUS) => dispatch(setOfferLoadStatus(loadStatus));
  const { locationConfig } = useLocationConfig();
  const configLoaded = locationConfig !== null;
  const {
    city: { id: cityId },
  } = useCity();
  const { active: mbRentActive } = useMbRent();
  const { startDate, endDate } = useSelectedDates();
  const { setValue, values } = useBooking();
  const selectedCategory = useSelectedCategory();

  // validates the selected category and updates it if the category is not valid
  const adjustSelectedVehicleCategory = (offers: Offer[]) => {
    const selectedCategoryIsValid =
      selectedCategory !== undefined && validateVehicleCategory(mergeOfferIntoCategory(selectedCategory, offers));
    if (!selectedCategoryIsValid) {
      if (mbRentActive) {
        const defaultMbRentCategory = locationConfig!!.partner
          ? getDefaultMbRentCategory(locationConfig!!.partner, offers)
          : null;
        setValue('mbr_category', { ...values.mbr_category, vehicleType: defaultMbRentCategory });
      } else {
        const defaultShareNowCategory = getDefaultShareNowCategory(locationConfig, vehicleCategories, offers);
        setValue('trip', { ...values.trip, vehicleType: defaultShareNowCategory });
      }
    }
  };

  const selectedPaymentProfileId = selectedPaymentProfile ? selectedPaymentProfile.paymentProfileId : null

  useDeepCompareEffect(() => {
    const fetchOffers = async () => {
      if (lastOfferCancelSourceRef.current) {
        lastOfferCancelSourceRef.current.cancel();
      }

      const offerCancelSource = createCancelToken();
      lastOfferCancelSourceRef.current = offerCancelSource;

      if (vehicleLoadStatus !== LOAD_STATUS.LOADED) return;

      if (webView && !paymentProfilesLoaded && !selectedPaymentProfile) {
        await dispatch(fetchPaymentProfiles());
        return;
      }

      if (configLoaded)
        try {
          setLoadStatus(LOAD_STATUS.LOADING);
          const { offers } = await getOffers(
            {
              payment_profile_id: selectedPaymentProfileId,
              locale: SUPPORTED_LANGUAGES.includes(language) ? language : DEFAULT_LANGUAGE,
              location: cityId,
              dropoff_at: endDate,
              pickup_at: startDate,
              build_series: buildSeries,
              token: webView ? await getJwt() : undefined,
              partner: mbRentActive ? 'mb_rent' : undefined,
            },
            offerCancelSource.token,
          );
          setOfferState(offers);
          setLoadStatus(LOAD_STATUS.LOADED);
          // set default value for webView or when a category is already selected
          if (webView || selectedCategory !== undefined) adjustSelectedVehicleCategory(offers);
        } catch (e) {
          console.log(e);
        }

      return () => {
        if (lastOfferCancelSourceRef.current) {
          lastOfferCancelSourceRef.current.cancel();
        }
      };
    };
    fetchOffers();
  }, [
    startDate,
    endDate,
    language,
    buildSeries,
    selectedPaymentProfileId,
    configLoaded,
    cityId,
    vehicleLoadStatus,
  ]);
};
