import { AirportZonesActions, SET_AIRPORT_ZONES } from './types';
import { LocationZone } from '@/services/location';

const initialState: { locationZones: LocationZone[] } = {
  locationZones: [],
};

export const airportZonesReducer = (state = initialState, action: AirportZonesActions) => {
  switch (action.type) {
    case SET_AIRPORT_ZONES:
      return { ...state, locationZones: [...action.payload] };
    default:
      return state;
  }
};
